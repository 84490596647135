<template>
  <div class="mt-4  px-5">
    <v-alert
        dense
        text
        shaped
        type="info">
      <b>Hinweis:</b>
      <div>
        Hier werden alle schuetzenbuch.de Mitglieder angezeigt, die Euren Verein beitreten wollen, oder schon
        beigetreten sind.<br>
        Über das "Action" Menu könnt Ihr bestätigen, dass dieses Mitglied zu Eurem Verein gehört. <br><br>
        ACHTUNG: Erst ab dann, kann sich das Mitglied schuetzenbuch Einträge anlegen!<br><br>
        Ebenfalls könnt Ihr über das "Action" Menü dem Mitglied die Funktion "Aufsicht" geben und die Möglichkeit,
        dass es schuetzenbuch.de Einträge der anderen Mitglieder genehmigen kann.
      </div>
    </v-alert>
    <v-data-table
        :headers="headers"
        :items="comp_ClubMember"
        sort-by="name"
        class="elevation-1"
        ref="ClubMember"
    >
      <template v-slot:top>
        <v-toolbar
            flat
            color="green lighten-1"
        ><h2>Mitglieder</h2>
          <v-spacer></v-spacer>
          <new-member-from-club></new-member-from-club>
          <v-btn icon
                 @click="getClubMember">
            <v-icon>mdi-reload</v-icon>
          </v-btn>
        </v-toolbar>
      </template>
      <template v-slot:item.isVAdmin="{ item }">
        <v-icon
            v-if="item.isVAdmin"
            class="d-flex justify-center"
            color="success">
          mdi-check
        </v-icon>
        <v-icon
            v-if="!item.isVAdmin"
            class="d-flex justify-center"
            color="red">
          mdi-window-close
        </v-icon>
      </template>

      <template v-slot:item.aufsicht_from="{ item }">
        <span v-if="item.aufsicht_from !=null">{{
            new Date(item.aufsicht_from.replace(" ", "T")).toLocaleString([], {
              dateStyle: 'short'
            })
          }}
          </span>
      </template>


      <template v-slot:item.can_aufsicht="{ item }">
        <v-icon
            v-if="item.can_aufsicht"
            class="d-flex justify-center"
            color="success">
          mdi-check
        </v-icon>
        <v-icon
            v-if="!item.can_aufsicht"
            class="d-flex justify-center"
            color="red">
          mdi-window-close
        </v-icon>
      </template>

      <template v-slot:item.rfid_tag="{ item }">

        <span v-if="item.rfid_tag" class="d-flex justify-center"> {{ item.rfid_tag }}</span>

        <v-icon
            v-if="!item.rfid_tag"
            class="d-flex justify-center"
            color="red">
          mdi-nfc-variant-off
        </v-icon>
      </template>


      <template v-slot:item.is_Mitglied="{ item }">
        <v-icon
            v-if="item.is_Mitglied != null"
            class="d-flex justify-center"
            color="success">
          mdi-check
        </v-icon>
        <v-icon
            v-else
            class="d-flex justify-center"
            color="red">
          mdi-window-close
        </v-icon>
      </template>
      <template v-slot:item.action_menu="{ item }">
        <v-menu offset-y>
          <template v-slot:activator="{ on, attrs }">
            <v-btn
                color="primary"
                dark
                v-bind="attrs"
                v-on="on"
            >
              <v-icon>mdi-arrow-down</v-icon>
            </v-btn>
          </template>
          <v-list shaped>
            <v-list-item-group
                color="primary">
              <v-list-item
                  @click="setMemberShip(item)"
                  v-if="item.is_Mitglied == null">
                <v-list-item-icon>
                  <v-icon
                      class="mr-1"
                      color="success">fas fa-people-arrows
                  </v-icon>
                </v-list-item-icon>
                <v-list-item-title>Ist ein Mitglied</v-list-item-title>
              </v-list-item>
              <v-list-item
                  @click="setAufsicht(item)"
                  v-if="item.is_Mitglied != null">
                <v-icon
                    v-if="item.can_aufsicht === 1"
                    class="mr-2">mdi-window-close
                </v-icon>
                <v-list-item-title
                    v-if="item.can_aufsicht === 1">
                  Aufsicht entziehen
                </v-list-item-title>

                <v-icon
                    v-if="item.can_aufsicht === 0 || item.can_aufsicht === null"
                    class="mr-2">fas fa-key
                </v-icon>
                <v-list-item-title
                    v-if="item.can_aufsicht === 0 || item.can_aufsicht === null">
                  Freigabe Aufsicht
                </v-list-item-title>
              </v-list-item>
              <v-divider></v-divider>
              <v-list-item
                  @click="setVAdmin(item)"
                  v-if="item.is_Mitglied != null">
                <v-icon v-if="item.isVAdmin === 1"
                        class="mr-2">mdi-window-close
                </v-icon>
                <v-list-item-title
                    v-if="item.isVAdmin === 1"
                >darf nicht mehr Genehmigen
                </v-list-item-title>

                <v-icon v-if="item.isVAdmin === 0"
                        class="mr-2">fas fa-plus-circle
                </v-icon>
                <v-list-item-title
                    v-if="item.isVAdmin === 0"
                >darf Genehmigen
                </v-list-item-title>
              </v-list-item>
              <v-divider v-if="comp_can_RFID"></v-divider>
              <v-list-item
                  @click="openDialogRFID(item)"
                  v-if="comp_can_RFID">
                <v-icon
                    class="mr-2">fas fa-credit-card
                </v-icon>
                <v-list-item-title>RFID Chip zuweisen</v-list-item-title>
              </v-list-item>
              <v-divider></v-divider>
              <v-list-item
                  @click="setMemberShip(item)"
                  v-if="item.is_Mitglied != null">
                <v-icon
                    class="mr-2"
                    color="error">fas fa-trash-alt
                </v-icon>
                <v-list-item-title>Mitglied löschen</v-list-item-title>
              </v-list-item>
            </v-list-item-group>
          </v-list>
        </v-menu>
      </template>
      <template v-slot:no-data>
        <v-btn
            color="primary"
            @click="initialize">
          Reset
        </v-btn>
      </template>
    </v-data-table>

    <v-dialog
        v-model="dialog_rfid"
        transition="dialog-top-transition"
        width="600">
      <v-card>
        <v-card-title class="text-h5 grey lighten-2">
          RFID Chip dem Mitglied zuweisen
          <v-spacer></v-spacer>
          <v-btn icon @click="closeDialogRFID">
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </v-card-title>
        <v-divider></v-divider>
        <v-card-text>
          <v-form ref="form" v-on:submit.prevent>
            <v-container>
              <v-row>
                <v-col cols="3">
                  <v-subheader class="mySubheader">Nummer des RFID Chips</v-subheader>
                </v-col>
                <v-col cols="8">
                  <span id="Hinweis_span">Im Idealfall vom Tastatur RFID Leser einfügen</span>
                  <v-text-field
                      v-model="RFIDToken"
                      :rules="[rules.counter]"
                  ></v-text-field>
                </v-col>
              </v-row>
            </v-container>
          </v-form>
        </v-card-text>
        <v-divider></v-divider>
        <v-card-actions class="card-actions">
          <v-spacer></v-spacer>
          <v-btn
              color="green darken-1"
              text
              @click="closeDialogRFID"
          >
            Abbrechen
          </v-btn>
          <v-btn
              color="green darken-1"
              text
              @click="setRFID()"
          >
            Speichern
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>


  </div>
</template>

<script>
import newMemberFromClub from "@/views/component/newMemberFromClub.vue";

export default {
  name: 'members',
  components: {newMemberFromClub},
  data: () => ({
    dialog: false,
    dialogDelete: false,
    dialog_rfid: false,
    loading: false,
    RFIDToken: '',
    RFID_UserID: '',
    headers: [
      {
        align: 'Name',
        sortable: false,
      },
      {text: 'Name', value: 'name'},
      {text: 'Vorname', value: 'vorname'},
      {text: 'Mitgliedsnummer', value: 'Mitgliedsnummer'},
      {text: 'Mitglied im Verein', value: 'is_Mitglied'},
      {text: 'Aufsicht im Verein seit', value: 'aufsicht_from'},
      {text: 'Freigabe zur Aufsicht', value: 'can_aufsicht'},
      {text: 'Genehmigen', value: 'isVAdmin'},
      {text: 'RFID Chip', value: 'rfid_tag'},
      {text: 'Action', value: 'action_menu', sortable: false}
    ],
    rules: {
      required: value => !!value || 'Pflichtfeld.',
      counter: value => value.length <= 200 || 'Max 200 Zeichen',
      email: value => {
        const pattern = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
        return pattern.test(value) || 'Ungültige E-Mail.'
      },
    },

  }),

  computed: {
    comp_ClubMember() {
      return this.$store.state.ClubApp.ClubMember;
    },
    comp_can_RFID() {
      return this.$store.getters['ClubAuth/can_RFID'];
    }

  },

  mounted() {
    this.getClubMember();
  },

  methods: {

    getClubMember() {
      this.$store.dispatch('ClubApp/getClubMember')
    },
    setVAdmin(item) {
      this.loading = true;

      var Data = {
        UserID: item.UserID,
        VadminID: item.id,
        VadminBol: item.isVAdmin,
      };
      this.$store.dispatch('ClubApp/setClubMemberAdmin', Data)
          .then(this.loading = false);
    },

    setAufsicht(item) {
      this.loading = true;

      var Data = {
        UserID: item.UserID,
        VadminID: item.id,
        can_aufsicht: item.can_aufsicht,
      };
      this.$store.dispatch('ClubApp/setClubMemberAufsicht', Data)
          .then(this.loading = false);
    },

    setMemberShip(item) {
      this.loading = true;

      var Data = {
        club_user_id: item.id,
        User_id: item.UserID,
        is_Member: (item.is_Mitglied != null) ? 1 : 0,
      };

      this.$store.dispatch('ClubApp/setClubMemberMemberShip', Data)
          .then(this.loading = false);
    },

    openDialogRFID(item) {
      this.dialog_rfid = true;
      this.RFID_UserID = item.UserID;

    },

    async setRFID() {
      const Data = {
        UserID: this.RFID_UserID,
        RFIDToken: this.RFIDToken
      };

      if (this.$refs.form.validate()) {
        await this.$store.dispatch('ClubApp/setMemberRFIDToken', Data)
            .then(
                this.dialog_rfid = false,
                this.RFID_UserID = '',
                this.RFIDToken = '',
            )
      }

    },
    closeDialogRFID() {
      this.dialog_rfid = false;
      this.RFID_UserID = '';
      this.RFIDToken = '';
    },


    initialize() {
      this.getClubMember();
    },

  },
}
</script>

<style scoped>
.mySubheader {
  font-weight: bold;
}

#Hinweis_span {
  font-weight: bold;
}
</style>

