<template>
  <div>
    <v-btn
        v-if="comp_can_RFID"
        color="indigo"
        dark=""
        class="mr-5 mb-2"
        @click="open_dialog()">
      <v-icon class="mr-2"
              x-small>
        fas fa-signature
      </v-icon>
      Neues Mitglied
    </v-btn>

    <v-dialog
        v-model="dialog"
        transition="dialog-top-transition"
        width="600">
      <v-card>
        <v-card-title class="text-h5 grey lighten-2">
          Neues Mitglied anlegen
          <v-spacer></v-spacer>
          <v-btn icon @click="dialog = false">
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </v-card-title>
        <v-divider></v-divider>
        <v-card-text>
          <v-form ref="form" v-on:submit.prevent>
            <v-container>
              <v-row>
                <v-col cols="3">
                  <v-subheader class="mySubheader">E-Mail</v-subheader>
                </v-col>
                <v-col cols="8">
                  <v-text-field
                      v-model="NewMember.Email"
                      :rules="[rules.required, rules.email]"
                      :error-messages="errorMessages"
                  ></v-text-field>
                </v-col>
              </v-row>
              <v-row>
                <v-col cols="3">
                  <v-subheader class="mySubheader">Vorname</v-subheader>
                </v-col>
                <v-col cols="8">
                  <v-text-field
                      v-model="NewMember.Vorname"
                      :rules="[rules.required]"
                      :error-messages="errorMessages"
                  ></v-text-field>
                </v-col>
              </v-row>
              <v-row>
                <v-col cols="3">
                  <v-subheader class="mySubheader">Nachname</v-subheader>
                </v-col>
                <v-col cols="8">
                  <v-text-field
                      v-model="NewMember.Nachname"
                      :rules="[rules.required]"
                      :error-messages="errorMessages"
                  ></v-text-field>
                </v-col>
              </v-row>
              <v-row>
                <v-col cols="3">
                  <v-subheader class="mySubheader">Mitglieds Nummer</v-subheader>
                </v-col>
                <v-col cols="8">
                  <v-text-field
                      v-model="NewMember.MitgliedID"
                      :rules="[rules.required]"
                      :error-messages="errorMessages"
                  ></v-text-field>
                </v-col>
              </v-row>
              <v-row>
                <v-col cols="3">
                  <v-subheader class="mySubheader">Passwort</v-subheader>
                </v-col>
                <v-col cols="8">
                  <span id="Hinweis_span">Aus einem Passwortgenerator einfügen</span>
                  <v-text-field
                      v-model="NewMember.Passwort"
                      :append-icon="show_password ? 'mdi-eye' : 'mdi-eye-off'"
                      :type="show_password ? 'text' : 'password'"
                      :rules="[rules.required]"
                      :error-messages="errorMessages"
                      @click:append="show_password = !show_password"
                  ></v-text-field>
                </v-col>
              </v-row>
              <v-row v-if="comp_can_RFID">
                <v-col cols="3">
                  <v-subheader class="mySubheader">Nummer des RFID Chips</v-subheader>
                </v-col>
                <v-col cols="8">
                  <span id="Hinweis_span">Im Idealfall vom Tastatur RFID Leser einfügen</span>
                  <v-text-field
                      v-model="NewMember.RFIDToken"
                      :rules="[rules.counter]"
                  ></v-text-field>
                </v-col>
              </v-row>
            </v-container>
          </v-form>
        </v-card-text>
        <v-divider></v-divider>
        <v-card-actions class="card-actions">
          <v-spacer></v-spacer>
          <v-btn
              color="green darken-1"
              text
              @click="close_dialog()"
          >
            Abbrechen
          </v-btn>
          <v-btn
              color="green darken-1"
              text
              @click="create_member()"
          >
            Speichern
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

  </div>
</template>

<script>

export default {
  name: 'newMemberFromClub',
  data: () => ({
    dialog: false,
    show_password: false,
    formHasErrors: false,
    errorMessages: '',
    DefaultMember: {
      Email: '',
      Vorname: '',
      Nachname: '',
      MitgliedID: '',
      Passwort: '',
      RFIDToken: '',
    },
    NewMember: {},
    rules: {
      required: value => !!value || 'Pflichtfeld.',
      counter: value => value.length <= 200 || 'Max 200 Zeichen',
      email: value => {
        const pattern = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
        return pattern.test(value) || 'Ungültige E-Mail.'
      },
    },

  }),
  computed: {
    comp_can_RFID() {
      return this.$store.getters['ClubAuth/can_RFID'];
    }
  },
  methods: {
    async create_member() {

      if (this.$refs.form.validate()) {
        await this.$store.dispatch('ClubApp/setCreateNewMember', this.NewMember)
            .then(
                this.dialog = false
            )
      }
    },

    open_dialog() {
      this.NewMember = Object.assign({}, this.DefaultMember)
      this.dialog = true;
    },
    close_dialog() {
      this.dialog = false;
    },


  },
}
</script>
<style scoped>

.mySubheader {
  font-weight: bold;
}

#Hinweis_span {
  font-weight: bold;
}
</style>